import React from 'react';
import { useState } from 'react';
import { Modal } from 'reactstrap';
import { DynamicForm } from "./DynamicForm";
import { Loader } from './Loader';

export const DynamicModal = ({config, toggleModal, ds, submitModes, disableSockets=false}) => {

    const [formSubmitDisabled, setFormSubmitDisabled] = useState(false)

    return (
      <Modal size={config.size} isOpen={config.visible} toggle={()=>toggleModal({})} keyboard={false} backdrop="static">
          <Loader loading={formSubmitDisabled} transparent />
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{!config.absoluteHeader && config.type ? config.type + " " : ""}{config.header}</h5>
            <button type="button" onClick={() => toggleModal({})} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <React.Fragment>
            {config.visible ? (
              <DynamicForm config={{...config, modal: true, toggleModal: toggleModal}} ds={ds} submitModes={submitModes} disableSockets={disableSockets} setFormSubmitDisabled={setFormSubmitDisabled} />
            ) : (
              <>
                <div id="modalBody" className="modal-body"></div>
                <div className="modal-footer">
                  <button type="button" onClick={() => toggleModal({})} className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                </div>
              </>
            )}
              
          </React.Fragment>
      </Modal>
    )
}