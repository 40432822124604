import http from "../core/http";
const group = 'locations';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const findDataRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getLocationTypesRequest =  async (data) => {
    return await http.post(`${group}/getLocationTypes`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}
