import { atom, selector } from "recoil";
import { createDataRequest, getLocationTypesRequest, updateDataRequest } from "../../../api/controller/LocationController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const locationListState = atom({
    key: 'locationListState',
    default: [],
})


export const modalConfigDefault = { size: "md", wizard: false }

export const locationFilterState = selector({
    key: 'locationFilterState',
    get: () => ([])
})
export const locationLocationTypeState = atom({
	key: 'locationLocationTypeState',
	default:{
		data:[],
		ovf:false,
	}
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const locationDataStructureState = selector({
    key: 'locationDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Location Type',
                type: 'select',
                field: 'type',
                order: { table: 1, form: 1 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: [{ enabled: false }], view: ['same_as:update.0'], 
                list: get(locationLocationTypeState),
                getRequest: getLocationTypesRequest
            },
            { 
                label: 'Name',
                type: 'text',
                order: { table: 2, form: 2 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'Description',
                type: 'text',
                order: { table: 3, form: 3 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
