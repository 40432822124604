import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  // Label,
  Row,
} from "reactstrap";
// Redux
import { Link, useLocation, withRouter } from "react-router-dom";
// availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation";
// action
// import images
// import profile from "../../assets/images/profile-img.png";
import bgImage from "../../assets/images/Picture2.jpg";
// import logoFull from "../../assets/images/logo_im2.png";
import logoOriginal from "../../assets/images/logoOriginal.png";
// import logo from "../../assets/images/logo.svg";
import {
  validatePasswordReset,
  initPasswordReset,
} from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { preloaderState, themeTypeState } from "../../state/GlobalState";
import notify from "../../custom/helpers/Notify";
import { passwordResetHashState } from "../../api/state/AuthState";
import moment from "moment";

const ForgotPassword = ({ history }) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [preloader, setPreloader] = useRecoilState(preloaderState);
  const setPasswordResetHash = useSetRecoilState(passwordResetHashState);
  const [form, setForm] = useState({ email: "" });
  const [themeType, setThemeType] = useRecoilState(themeTypeState);

  const location = useLocation();

  useEffect(() => {
    setPreloader(true);
    if (location.search) {
      var params = JSON.parse(
        '{"' +
          decodeURI(location.search.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (params.ref) {
        async function passwordResetFn(params) {
          const response = await validatePasswordReset({ ref: params.ref });
          if (response?.Status === "success" && response?.Ref) {
            setPasswordResetHash(response.Ref);
            setPreloader(false);
            history.push("/change-password");
          } else {
            setPreloader(false);
            notify({
              status: "error",
              message: "Invalid Password Reset Link. Please try again later!",
            });
            history.push("/login");
          }
        }
        passwordResetFn(params);
      } else {
        setPreloader(false);
        history.push("/login");
      }
    } else {
      setPreloader(false);
    }
  }, [history, location.search, setPasswordResetHash, setPreloader]);

  useEffect(() => {
    setThemeType("light");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handleValidSubmit
  const handleValidSubmit = async (event) => {
    event.preventDefault();
    setSubmitDisabled(true);
    const response = await initPasswordReset({ Email: form.email });
    handleResponse(response);
    setSubmitDisabled(false);
  };

  return preloader ? (
    <div id="preloader">
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2"></i>
        </Link>
      </div> */}
      <div>
        <img
          src={bgImage}
          alt=""
          style={{
            zIndex: "-10",
            position: "fixed",
            opacity: 0.8,
            objectFit: "cover",
          }}
        />
      </div>
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <Card className="overflow-hidden mt-5">
                <div className="bg-soft border-bottom">
                  <Col className="col-12 text-center pt-3 mt-2">
                    <img src={logoOriginal} height="60px" alt="" />
                  </Col>
                  <Row>
                    <Col className="col-12 text-center mb-2">
                      <div
                        className={
                          "px-3 mt-4 " +
                          (themeType === "dark" ? "text-primary" : "text-white")
                        }
                      >
                        {/* <h1
                          className={
                            themeType === "dark" ? "text-primary" : "text-white"
                          }
                        ></h1> */}
                        <p></p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-2">
                  <div className="col-12 pt-3 p-2">
                    <h5
                      className={
                        themeType === "dark" ? "text-white" : "text-primary"
                      }
                    >
                      Forgotten password ?
                    </h5>
                    <p
                      className={
                        themeType === "dark" ? "text-white" : "text-primary"
                      }
                    >
                      Enter your email to rest your password.
                    </p>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={handleValidSubmit}
                    >
                      <FormGroup>
                        {/* <Label>Email</Label> */}
                        <Input
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          value={form.email}
                          onChange={(e) =>
                            setForm({ ...form, email: e.target.value })
                          }
                          type="email"
                          required
                        />
                      </FormGroup>
                      <Row className="form-group">
                        <Col className=" text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light w-100"
                            disabled={submitDisabled}
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div className="mt-5 text-center">
                    <p className={themeType === "dark" ? "text-white" : ""}>
                      <Link
                        to="login"
                        className={
                          "font-weight-medium " +
                          (themeType === "dark" ? "text-info" : "text-muted")
                        }
                      >
                        <i className="mdi mdi-arrow-left mr-1"></i> Go back to
                        Login
                      </Link>
                    </p>
                  </div>
                  <div className="mt-5 text-center">
                    <div style={{ fontSize: "10px" }}>
                      © {moment().year()} Maldives Aiports Company Limited
                      (MACL)
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ForgotPassword);
