import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import SimpleBar from "simplebar-react";
import {
  getNotificationsRequest,
  markAllAsReadRequest,
} from "../../../api/controller/NotificationController";
import { notificationState } from "../../../api/state/NotificationState";
import echo from "../../../api/core/echo";
import { authState, userState } from "../../../api/state/AuthState";
import { useHistory } from "react-router-dom";
// import { themeTypeState } from "../../../state/GlobalState";

const NotificationDropdown = (props) => {
  const isAuth = useRecoilValue(authState);
  const user = useRecoilValue(userState);
  const [menu, setMenu] = useState(false);
  const [mrTimer, setMrTimer] = useState(null);
  const [notifications, _setNotifications] = useRecoilState(notificationState);
  const notificationsRef = useRef(notifications);
  const setNotifications = (data) => {
    notificationsRef.current = data;
    _setNotifications(data);
  };
  const history = useHistory();
  // const [themeType, setThemeType] = useRecoilState(themeTypeState);

  useEffect(() => {
    async function getNotifications() {
      if (isAuth && user) {
        const response = await getNotificationsRequest();
        if (response && response.data) {
          setNotifications(response.data || []);
        }
        echo
          .private(`Notification-Primary.${user.id}`)
          .listen(`.NotificationEvents`, (data) => {
            if (data.event === "Create") {
              setNotifications([data.data, ...notificationsRef.current]);
            } else if (data.event === "Updated") {
              setNotifications(
                notificationsRef.current.map((v) => {
                  return parseInt(v.id) === parseInt(data.data.id)
                    ? data.data
                    : v;
                })
              );
            }
          });
      }
    }
    getNotifications();
    return () => {
      echo.leave(`Notification-Primary.${user?.id}`);
      clearTimeout(mrTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, user]);

  const toggle = () => {
    setMenu((prevState) => !prevState);
  };

  useEffect(() => {
    if (menu) {
      mrTimer && clearTimeout(mrTimer);
      setMrTimer(setTimeout(() => markAllAsReadRequest(), 2000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  const notificationNumber = notifications.length;
  let unreadNotifications = notifications.filter(
    (item) => !item.read_at
  ).length;

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={
              "bx bx-bell" + (unreadNotifications > 0 ? " bx-tada" : "")
            }
          ></i>
          {unreadNotifications > 0 ? (
            <span
              className="badge badge-danger badge-pill"
              style={{ backgroundColor: "#f46a6a" }}
            >
              {unreadNotifications}
            </span>
          ) : null}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-xl p-0" end>
          <div
            className="p-3"
            style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
          >
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 text-muted">Notifications</h6>
              </Col>
              <div className="col-auto text-muted">
                {props.settings.enableViewAll && notificationNumber > 0 ? (
                  <Link to={props.settings.notificationPage} className="small text-muted">
                    {" "}
                    View All
                  </Link>
                ) : null}
              </div>
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: props.settings.height }}>
            {notificationNumber > 0 ? (
              notifications.map((notification, key) => {
                return (
                  <a
                    key={key}
                    href={notification.link}
                    className="text-reset notification-item"
                    onClick={(e) => {
                      e.preventDefault();
                      if (notification.link) {
                        if (
                          notification.link.startsWith("http") ||
                          notification.link.startsWith("www")
                        ) {
                          window.location.replace(notification.link);
                        } else if (
                          notification.module_id === process.env.REACT_APP_UUID
                        ) {
                          history.push(notification.link);
                        } else {
                          window.location.replace(
                            process.env.REACT_APP_API_URL +
                              "notifications/redirect?id=" +
                              notification.id
                          );
                        }
                        setMenu(false);
                      }
                    }}
                    style={{ cursor: notification.link ? "pointer" : "auto" }}
                  >
                    <div
                      className="media"
                      style={{
                        padding: "1em",
                        borderBottom: "1px solid rgba(0,0,0,0.1)",
                        backgroundColor: notification.read_at
                          ? "white"
                          : "rgba(0,0,0,0.05)",
                      }}
                    >
                      {notification.avatar_type === "icon" ? (
                        <div
                          className="avatar-xs"
                          style={{ marginRight: "1rem" }}
                        >
                          <span
                            className={
                              "avatar-title rounded-circle font-size-16 " +
                              notification.avatar_bg
                            }
                          >
                            <i className={notification.avatar_path}></i>
                          </span>
                        </div>
                      ) : (
                        <img
                          src={notification.avatar_path}
                          className="rounded-circle avatar-xs"
                          alt="user-pic"
                          style={{ marginRight: "1rem" }}
                        />
                      )}

                      <div className="media-body text-muted">
                        <h6 className="mt-0 mb-1 text-muted">{notification.title}</h6>
                        <div className="font-size-12" >
                          <p className="mb-1 text-muted" >{notification.description}</p>
                          <p className="mb-0 text-muted">
                            <i className="mdi mdi-clock-outline text-muted"></i>{" "}
                            {moment(notification.created_at).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })
            ) : (
              <Link
                key="0"
                to="#"
                className="text-reset notification-item m-3"
                style={{ cursor: "auto" }}
              >
                <div className="media text-muted">
                  <div className="media-body">
                    <div
                      className="font-size-12 text-muted"
                      
                    >
                      <h5 className="mb-0 text-center">
                        You have no notifications!
                      </h5>
                    </div>
                  </div>
                </div>
              </Link>
            )}
          </SimpleBar>

          <div className="p-2 border-top">
            {props.settings.enableViewAll && notificationNumber > 0 ? (
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center text-muted"
                to={props.settings.notificationPage}
              >
                View all
              </Link>
            ) : null}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;
