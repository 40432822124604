import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/TransactionController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import moment from "moment";

export const transactionListState = atom({
    key: 'transactionListState',
    default: [],
})


export const modalConfigDefault = { size: "lg", wizard: false }


export const transactionFilterTypeValueState = atom({
    key: 'transactionFilterTypeValueState',
    default: { label: 'All', value: 'All' },
})
export const transactionFilterPeriodValueState = atom({
    key: 'transactionFilterPeriodValueState',
    default: [moment().subtract(7,'days').format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
})

export const transactionFilterState = selector({
    key: 'transactionFilterState',
    get: ({ get }) => ([
        {
            label: 'Transaction Type',
            size: 6,
            required: true,
            type: 'select',
            value: get(transactionFilterTypeValueState),
            list: {
                ovf: false,
                data:[
                    { label: 'All', value: 'All' },
                    { label: 'Service Requisition', value: 'Service Requisition' },
                    { label: 'Vehicle Escort', value: 'Vehicle Escort' },
                ]
            }
        },
        {
            label: 'Period',
            size: 6,
            // offset: 6,
            required: true,
            type: 'dateRange', // date, dateRange & select only
            value: get(transactionFilterPeriodValueState), // String date "yyyy-mm-dd" for date | Array of 2 dates for dateRange | Object as shown for Select { label: "Label", value: 0 }
            list: [], // only for select -> Array should have Objects as shown { label: "Label", value: 0 }
        },
    ]),
    set: ({ set }, e) => {
        if(e.filterLabel === 'Transaction Type'){
            set(transactionFilterTypeValueState, e.value)
        } else if(e.filterLabel === 'Period'){
            set(transactionFilterPeriodValueState, e.value)
        }
    }
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const transactionDataStructureState = selector({
    key: 'transactionDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Transaction Type',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'Service Requisition', value: 'Service Requisition' },
						{ label: 'Vehicle Escort', value: 'Vehicle Escort' },
					]
				},
            },
			{ 
                label: 'Date',
                type: 'datepicker',
                order: { table: 2, form: 2 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Currency',
                type: 'select',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'MVR', value: 'MVR' },
						{ label: 'USD', value: 'USD' },
					]
				},
            },
			{ 
                label: 'Tax Rate',
                type: 'number',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Subtotal',
                type: 'number',
                order: { table: 3, form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Tax Amount',
                type: 'number',
                order: { table: 4, form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Net Amount',
                type: 'number',
                order: { table: 5, form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Payment Method',
                type: 'select',
                order: { form: 8 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'BML Payment Gateway', value: 'BML Payment Gateway' },
					]
				},
            },
			{ 
                label: 'Gateway Transaction ID',
                type: 'text',
                order: { form: 9 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: false, size: { xs: 12, md: 6 } }], 
            },
			{ 
                label: 'Status',
                type: 'text',
                order: { table: 6, form: 6 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 } }], 
            },
			{ 
                label: 'Transaction Details',
                type: 'repeater',
                order: { form: 11 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'Item Name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Item Code',
                		type: 'text',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Unit Price',
                		type: 'number',
                		order: { form: 3 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Quantity',
                		type: 'number',
                		order: { form: 4 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Total',
                		type: 'number',
                		order: { form: 5 }, 
                		create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 4 }  }], 
            		},
					
                ]
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
