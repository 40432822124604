import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import {
  changeLayout,
  changeTopbarTheme,
} from "../../custom/helpers/Layout";
import { navigationState } from "../../state/NavigationState";
import { useRecoilValue } from "recoil";
import { activeNavState } from "../../state/GlobalState";


const Layout = props => {
  const [openMenu, setOpenMenu] = useState(false);
  const topbarTheme = 'dark';
  const navigation = useRecoilValue(navigationState);

  const activeNav = useRecoilValue(activeNavState)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])

  useEffect(() => {
    document.title = activeNav?.name ? activeNav.name + " | " + process.env.REACT_APP_APP_NAME : process.env.REACT_APP_APP_NAME;
  }, [activeNav])

  useEffect(() => {
    changeLayout("horizontal")
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
  }, []);

  return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header theme={topbarTheme}
                  isMenuOpened={openMenu}
                  toggleMenuCallback={() => setOpenMenu(!openMenu)} />
          <Navbar menuOpen={openMenu} navItems={navigation} />
          <div className="main-content">       
            {props.children}
          </div>
        </div>
      </React.Fragment>
  );
}


export default withRouter(Layout);
