import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";

import {
  changeLayout,
  changeLeftSidebarTheme,
  changeLeftSidebarType,
  changeTopbarTheme,
  changeBodyThemeType,
} from "../../custom/helpers/Layout";
import {
  activeNavState,
  leftSidebarTypeState,
  themeTypeState,
} from "../../state/GlobalState";
import { useRecoilState, useRecoilValue } from "recoil";

const Layout = (props) => {
  const activeNav = useRecoilValue(activeNavState);
  const themeType = useRecoilValue(themeTypeState);

  const [isMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );
  const topbarTheme =
    themeType === "light" || themeType === "dark" ? "dark" : "light";
  const leftSideBarTheme = "dark";
  const [leftSidebarType, setLeftSidebarType] =
    useRecoilState(leftSidebarTypeState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  useEffect(() => {
    document.title = activeNav?.name
      ? activeNav.name + " | " + process.env.REACT_APP_APP_NAME
      : process.env.REACT_APP_APP_NAME;
  }, [activeNav]);

  useEffect(() => {
    changeLeftSidebarTheme(leftSideBarTheme);
    changeTopbarTheme(topbarTheme);
    changeBodyThemeType(themeType);

    if (leftSidebarType) {
      changeLeftSidebarType(leftSidebarType, isMobile);
    }
  }, [leftSidebarType, isMobile, themeType, leftSideBarTheme, topbarTheme]);

  const toggleMenuCallback = () => {
    if (leftSidebarType === "default") {
      setLeftSidebarType("condensed");
      changeLeftSidebarType("condensed", isMobile);
    } else if (leftSidebarType === "condensed") {
      setLeftSidebarType("default");
      changeLeftSidebarType("default", isMobile);
    }
  };

  useEffect(() => {
    changeLayout("vertical");
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSidebarType}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
