import React, {useEffect, useState} from "react";
import { withRouter } from "react-router-dom";

import { navigationState } from "../state/NavigationState";
import { activeNavState, leftSidebarTypeState, themeTypeState } from "../state/GlobalState";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  changeLeftSidebarTheme,
  changeLeftSidebarType,
  changeTopbarTheme,
  changeBodyThemeType,
} from "../custom/helpers/Layout";

const NonAuthLayout = props => {

  const [activeNav, setActiveNav] = useRecoilState(activeNavState)
  const navigation = useRecoilValue(navigationState)
  const themeType = useRecoilValue(themeTypeState)

  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const topbarTheme = themeType === 'mixed' || themeType === 'dark' ? 'dark' : 'light';
  const leftSideBarTheme = themeType === 'mixed' || themeType === 'dark' ? 'dark' : 'light';
  const leftSidebarType = useRecoilValue(leftSidebarTypeState);
  
  useEffect(() => {
    changeLeftSidebarTheme(leftSideBarTheme)
    changeTopbarTheme(topbarTheme);
    changeBodyThemeType(themeType);

    if (leftSidebarType) {
      changeLeftSidebarType(leftSidebarType, isMobile)
    }
  }, [leftSidebarType, isMobile, themeType, leftSideBarTheme, topbarTheme])

  useEffect(() => {
    window.scrollTo(0, 0);
    const getActiveNav = (nav) => {
        if(nav.children.length > 0){
            return nav.children.map((c)=>(getActiveNav(c))).join('') 
        } else if(props.location.pathname.replace(/^\/|\/$/g, '') === nav.link?.replace(/^\/|\/$/g, '')){
            setActiveNav(nav)
            return nav.highlight?.replace(/^\/|\/$/g, '')
        } else {
            return ''
        }
    }
    navigation.map((m)=>(getActiveNav(m))).join('')
  }, [props.location.pathname, navigation, setActiveNav])

  useEffect(() => {
    document.title = activeNav?.name ? activeNav.name + " | " + process.env.REACT_APP_APP_NAME : process.env.REACT_APP_APP_NAME;
  }, [activeNav])

  return (
    <React.Fragment>
        {props.children}
    </React.Fragment>
  );
}

export default withRouter(NonAuthLayout);
