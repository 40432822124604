import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import megaMenuImg from "../../assets/images/megamenu-img.png"
import { megaMenuState } from '../../state/NavigationState';
import {megaMenuNameState} from '../../state/GlobalState';
import {Link} from "react-router-dom";


export const MegaMenu = () => {
    
    const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false)
    const megaMenu = useRecoilValue(megaMenuState);
    const megaMenuName = useRecoilValue(megaMenuNameState)

    return (
        megaMenu.length > 0 ? (
            <Dropdown className="dropdown-mega d-none d-lg-block ml-2" isOpen={isMegaMenuOpen}toggle={() => setIsMegaMenuOpen(!isMegaMenuOpen)}>
                <DropdownToggle className="btn header-item waves-effect" caret tag="button">
                    {megaMenuName} <i className="mdi mdi-chevron-down"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-megamenu">
                    <Row>
                        <Col md={10}>
                            <Row>
                                {megaMenu.map((header,key)=>(
                                <Col key={key} md={header.size}>
                                    <h5 className="font-size-14 mt-0">{header.name}</h5>
                                    <ul className="list-unstyled megamenu-list">
                                    {header.children.map((item, idx)=>(
                                        <li key={key+'-'+idx} onClick={() => setIsMegaMenuOpen(false)}><Link to={item.link}>{item.name}</Link></li>
                                    ))}
                                    </ul>
                                </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col md={2}>
                            <div><img src={megaMenuImg} alt="" className="img-fluid mx-auto d-block" /></div>
                        </Col>
                    </Row>
                </DropdownMenu>
            </Dropdown>
        ) : null
        
    )

}