import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Card, CardBody, Col, Container, Row, Button, Label } from "reactstrap";
import notify from "../../../custom/helpers/Notify";
import "../../../pages/Tables/datatables.scss";
import { ConfirmDialog } from "../../../custom/components/ConfirmDialog";
import {
  pageState,
  lastAPIActionTimeState,
  // themeTypeState,
} from "../../../state/GlobalState";
import { DynamicModal } from "../../../custom/components/DynamicModal";
import "flatpickr/dist/themes/material_blue.css";
import {
  getDataListRequest,
  getBusinessTypeListRequest,
  getCountryListRequest,
  getServiceCategoryListRequest
} from "../../../api/controller/ProfileController"; // CHANGE (controller name)
import {
  formModes,
  modalConfigDefault,
  profileDataStructureState as dataStructureState,
  profileListState as dataListState,
  profileBusinessTypeState,
  profileRegisteredCountryState,
  profileServiceCategoryState,
} from "./state"; // CHANGE (dataList & structure stateName)
// import { Loader } from "../../../custom/components/Loader";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userState } from "../../../api/state/AuthState";

const Profile = () => {
  const model = "Company Profile"; // CHANGE
  const models = "Company Profile"; // CHANGE

  // GET & SET SELECTS
  const selects = [
    {
      model: "BusinessType",
      getFn: getBusinessTypeListRequest,
      setFn: useSetRecoilState(profileBusinessTypeState),
      ref: useRef([]),
      keys: { label: "name", value: "name" },
    },
    {
      model: "RegisteredCountry",
      getFn: getCountryListRequest,
      setFn: useSetRecoilState(profileRegisteredCountryState),
      ref: useRef([]),
      keys: { label: "name", value: "name" },
    },
    {
      model: "ServiceCategory",
      getFn: getServiceCategoryListRequest,
      setFn: useSetRecoilState(profileServiceCategoryState),
      ref: useRef([]),
      keys: { label: "name", value: "id" },
    },
    // { model: 'ModelName', getFn: getModelListRequest, setFn: useSetRecoilState(modelState), ref: useRef([]), keys: { label: 'name', value: 'id' } },
  ];

  const [dataList, setDataList] = useRecoilState(dataListState);
  // eslint-disable-next-line
  const setPage = useSetRecoilState(pageState);
  const lastAPIActionTime = useRecoilValue(lastAPIActionTimeState);
  // const [tableLoading, setTableLoading] = useState(true);
  // const themeType = useRecoilValue(themeTypeState);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmID, setConfirmID] = useState(null);
  const [confirmParams, setConfirmParams] = useState({});
  const [confirmConfig, setConfirmConfig] = useState({
    n: null,
    itle: null,
    ody: null,
  });
  const [cuModalConfig, _setCUModalConfig] = useState({
    visible: false,
    header: model,
    type: null,
    typeKey: null,
    data: {},
    ...modalConfigDefault,
  });
  const cuModalConfigRef = useRef(cuModalConfig);
  const setCUModalConfig = (data) => {
    cuModalConfigRef.current = data;
    _setCUModalConfig(data);
  };
  const [actionsDisabled, setActionsDisabled] = useState(true);
  const dataStructure = useRecoilValue(dataStructureState);

  useEffect(() => {
    setPage(
      (models.charAt(0).toLowerCase() + models.slice(1)).split(" ").join("")
    );
  }, [setPage]);

  useEffect(() => {
    async function fetchData(filter) {
      // setTableLoading(true);
      setDataList([]);
      const response = await getDataListRequest(filter);
      if (response && response.Status !== "error") {
        setDataList(response.data);
      } else {
        notify({ status: "error", message: response.Message });
      }
      // setTableLoading(false);
    }
    let fetch = true;
    let postData = {};
    if (fetch) {
      fetchData(postData);
    }
    // eslint-disable-next-line
  }, [lastAPIActionTime]);

  useEffect(() => {
    async function fetchSelectData() {
      setActionsDisabled(true);
      await Promise.all(
        selects.map(async (select) => {
          if (!(select.persist && select.read?.length > 0)) {
            const response = await select.getFn();
            const setFnRef = (data) => {
              select.ref.current = data;
              select.setFn(data);
            };
            if (response && response.Status !== "error") {
              setFnRef({
                ovf: response.ovf,
                data: select.exactValue
                  ? response.data
                  : response.data?.map((val) => ({
                      label: val[select.keys.label || "name"],
                      value: val[select.keys.value || "id"],
                    })),
              });
            } else {
              notify({ status: "error", message: response.Message });
            }
          }
        })
      );
      setActionsDisabled(false);
    }
    fetchSelectData();
    return () => {
      setDataList([]);
      selects
        .filter((s) => !(s.persist && s.read?.length > 0))
        .forEach((s) => {
          s.ref.current = [];
          s.setFn([]);
        });
    };
    // eslint-disable-next-line
  }, []);

  const toggleCUModal = ({
    row = {},
    mode = null,
    modeKey = 0,
    size = null,
    wizard = null,
  }) => {
    setCUModalConfig({
      ...cuModalConfig,
      visible: !cuModalConfig.visible,
      type: typeof mode === "string" ? mode : null,
      typeKey: modeKey,
      data: row,
      size: size || cuModalConfig.size,
      wizard: wizard !== null ? wizard : cuModalConfig.wizard,
    });
  };

  const toggleConfirmModal = ({
    row = {},
    params = {},
    request = null,
    title = null,
    body = null,
  }) => {
    setConfirmID(row ? row.id : null);
    setConfirmParams(params);
    setConfirmConfig({ fn: request, title: title, body: body });
    setConfirmModalVisible(!confirmModalVisible);
  };

  const user = useRecoilValue(userState);

  const modifyProfile = () => {
    toggleCUModal({
      row: dataList || { legal_name: user?.name },
      mode: "Update",
      size: "xl",
    });
  };

  // temp
  // useEffect(() => {
  //   console.log(dataList);
  // }, [dataList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
              <h3>
                {models}{" "}
                  <Button
                    disabled={actionsDisabled}
                    color="primary"
                    className="ml-2 waves-effect waves-light"
                    type="submit"
                    onClick={() => modifyProfile()}
                  >
                    <i className="mdi mdi-pencil d-block font-size-12"></i>
                  </Button>
              </h3>
            </Col>
          </Row>

          {/*--------------------------------------profile info start----------------------------------*/}

          {dataList ? (
            <>
            <Card className="d-xs-block">
            <CardBody>
              <h4 className="mt-0 mb-0 text-center">{dataList?.legal_name}</h4>
              <hr />
              <Row>
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Label>
                    <b>Company Name</b>
                  </Label>
                  <p>{dataList?.legal_name}</p>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Label>
                    <b>Bussiness Type</b>
                  </Label>
                  <p>{dataList?.business_type}</p>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Label>
                    <b>Website</b>
                  </Label>
                  <p>{dataList?.website}</p>
                </Col>
              </Row>

              <Row className="mt-3 mb-3">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Label>
                    <b>Company Address</b>
                  </Label>
                  {dataList?.addresses?.map((address, key) => (
                    <p key={key}>{address.display_name}</p>
                  ))}
                </Col>
              </Row>

              <Row className="mt-2 mb-2">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Label>
                    <strong>Company Contacts</strong>
                  </Label>
                  <p>
                    {dataList?.contact_numbers?.map(
                      (contact, key) =>
                        (key === 0 ? "" : " / ") + contact.contact_number
                    )}
                  </p>
                </Col>
              </Row>

              <Row className="mt-2 mb-2">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Label>
                    <b>Company Emails</b>
                  </Label>
                  <p>
                    {dataList?.emails?.map(
                      (email, key) => (key === 0 ? "" : " / ") + email.email
                    )}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/*--------------------------------------Reprentative  & info start----------------------------------*/}
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Card className="d-xs-block">
                <CardBody>
                  <h4 className="mt-0 mb-4 ">
                    {" "}
                    Representative Info
                  </h4>
                 
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={4}>
                      <Label>
                        <b>Representative name</b>
                      </Label>
                      <p>{dataList?.representative_name}</p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                      <Label>
                        <b>Representative designation</b>
                      </Label>
                      <p>{dataList?.representative_designation}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6}>
              <Card className="d-xs-block">
                <CardBody>
                  <h4 className="mt-0 mb-4 "> Registration Info</h4>
             
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={4}>
                      <Label>
                        <b>Registration number</b>
                      </Label>
                      <p>{dataList?.registration_number}</p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                      <Label>
                        <b>Registered Country</b>
                      </Label>
                      <p>{dataList?.registered_country}</p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4}>
                      <Label>
                        <b>Tax Registration Number</b>
                      </Label>
                      <p>{dataList?.tax_registration_numbers}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*--------------------------------------Documents  & Year of services start----------------------------------*/}
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Card className="d-xs-block">
                <CardBody>
                  <h4 className="mt-0 mb-4 ">Company Documents</h4>
                
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Label>
                        <b>Business permit</b>
                      </Label>
                      <p>
                        <a
                          href={
                            process.env.REACT_APP_API_PUBLIC_URL +
                            dataList?.business_permit_authorization
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Label>
                        <b>Company registration</b>
                      </Label>
                      <p>
                        <a
                          href={
                            process.env.REACT_APP_API_PUBLIC_URL +
                            dataList?.company_registration
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Label>
                        <b>Incumbency certificate</b>
                      </Label>
                      <p>
                        <a
                          href={
                            process.env.REACT_APP_API_PUBLIC_URL +
                            dataList?.incumbency_certificate
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Label>
                        <b>Profile sheet</b>
                      </Label>
                      <p>
                        <a
                          href={
                            process.env.REACT_APP_API_PUBLIC_URL +
                            dataList?.profile_sheet
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Label>
                        <b>Tax registration certificate</b>
                      </Label>
                      <p>
                        <a
                          href={
                            process.env.REACT_APP_API_PUBLIC_URL +
                            dataList?.tax_registration_certificate
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6}>
              <Card className="d-xs-block">
                <CardBody>
                  <h4 className="mt-0 mb-4"> Years</h4>
                  
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Label>
                        <b>Years of incorporation</b>
                      </Label>
                      <p>{dataList?.year_of_incorporation}</p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Label>
                        <b>Years establised internationally</b>
                      </Label>
                      <p>{dataList?.years_established_internationally}</p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Label>
                        <b>Years established locally</b>
                      </Label>
                      <p>{dataList?.years_established_locally}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
            </>
          ) : (
            <Row>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <div className="mb-4">
                      <h4 style={{cursor: 'pointer'}} onClick={() => modifyProfile()}>Please Click Here to Complete your Profile!</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
          {/*-----------------------------------------------end------------------------------------*/}

          <DynamicModal
            config={cuModalConfig}
            toggleModal={toggleCUModal}
            submitModes={formModes}
            ds={dataStructure}
            disableSockets={true}
          />

          <ConfirmDialog
            id={confirmID}
            params={confirmParams}
            config={confirmConfig}
            visible={confirmModalVisible}
            toggleModal={toggleConfirmModal}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Profile);
