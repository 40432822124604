
import http from "../core/http";

const group = 'guest';


export const initEmail =  async (data) => {
    return await http.post(`${group}/initEmail`, data).then(res => res.data).catch(http.defaultCatch);
}

export const completeEmail =  async (data) => {
    return await http.post(`${group}/completeEmail`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getCountryListRequest =  async (data) => {
    return await http.post(`${group}/getCountryList`, data).then(res => res.data).catch(http.defaultCatch);
}

export const completeRegistration = async (data) => {
    return await http.post(`${group}/register`, data).then(res => res.data).catch(http.defaultCatch);
}