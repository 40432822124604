
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/UserController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const userListState = atom({
    key: 'userListState',
    default: [],
})
export const userRoleState = atom({
  key: 'userRoleState',
  default: {
    data: [],
    ovf: false,
  },
})
export const userModuleState = atom({
  key: 'userModuleState',
  default: {
    data: [],
    ovf: false,
  },
})
export const userServiceCategoryState = atom({
  key: 'userServiceCategoryState',
  default: {
    data: [],
    ovf: false,
  },
})
export const userLocationState = atom({
  key: 'userLocationState',
  default: {
    data: [],
    ovf: false,
  },
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const userFilterActiveValueState = atom({
  key: 'userFilterActiveValueState',
  default: { label: "Yes", value: true },
})
export const userFilterTypeValueState = atom({
  key: 'userFilterTypeValueState',
  default: { label: "Admin", value: "Admin" },
})

export const userFilterState = selector({
  key: 'userFilterState',
  get: ({ get }) => (
    [
      {
          label: 'Active',
          size: 6,
          required: true,
          type: 'select',
          value: get(userFilterActiveValueState),
          list: {
            ovf: false,
            data:[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]
          }
      },
      {
        label: 'Type',
        size: 6,
        required: true,
        type: 'select',
        value: get(userFilterTypeValueState),
        list: {
          ovf: false,
          data:[
            { label: "Admin", value: "Admin" },
            { label: "Guest", value: "Guest" },
          ]
        }
    }
    ]
  ),
  set: ({ set }, e) => {
      if(e.filterLabel === 'Active'){
          set(userFilterActiveValueState, e.value)
      } else if(e.filterLabel === 'Type'){
          set(userFilterTypeValueState, e.value)
      }
  }
})

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const userDataStructureState = selector({
    key: 'userDataStructureState',
    get: ({ get }) => (
        [
          { 
            label: "Name",
            type: "text",
            order: { table: 1, form: 1 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 6 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          { 
            label: "RCNO",
            type: "text",
            order: { table: 2, form: 2 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 6 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          { 
            label: "Email",
            type: "email",
            order: { table: 3, form: 3 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 6 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          { 
            label: "Type",
            type: "select",
            rootValue: true,
            order: { form: 3.1 },
            create: [{ enabled: true, size: { xs: 12, md: 6 }, default: "Admin" }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: {
              ovf: false,
              data:[
                { label: "Admin", value: "Admin" },
                { label: "Guest", value: "Guest" },
              ]
            }
          },
          { 
            label: "Mobile Number",
            type: "text",
            order: { table: 3.2, form: 3.2 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 6 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          {
            label: "Roles",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 5 },
            create: [{ enabled: true, size: { xs: 12, md: 12} }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(userRoleState),
          },
          {
            label: "Active",
            type: "switch",
            order: { form: 6 },
            create: [{ enabled: true, size: { xs: 4, md: 2 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },
          {
            label: "Is Locked",
            type: "switch",
            order: { table: 7, form: 7 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 4, md: 2 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },
          {
            label: "Approved",
            type: "switch",
            order: { table: 7.1, form: 7.1 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 4, md: 2 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },
          { 
            label: "Password",
            type: "text",
            order: { form: 8 },
            create: [{ enabled: true, size: { xs: 12, md: 6 }, default: 'welcome@123', validations: 'readOnly', }],
          },
          { 
            label: "Service Categories",
            field: "company.service_categories",
            type: "multiselect",
            order: { form: 9 },
            create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }, 
              // validations: 'hidden_if:Type,Admin' 
            }], view: [ 'same_as:update.0' ],
            list: get(userServiceCategoryState)
          },
          { 
            label: "Locations",
            field: "company.locations",
            type: "multiselect",
            order: { form: 10 },
            create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }, 
              // validations: 'hidden_if:Type,Admin' 
            }], view: [ 'same_as:update.0' ],
            list: get(userLocationState)
          },
          { 
            label: 'Utility Meters',
            field: 'company.utility_meters',
            type: 'repeater',
            order: { form: 11 },
            create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false, 
              // validations: 'hidden_if:Type,Admin' 
            }], view: ['same_as:update.0'],
            children: [
              { 
                label: 'Type',
                type: 'select',
                rootValue: true,
                order: { form: 1 }, 
                create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 6 } }], view: ['same_as:update.0'], 
                list: {
                  ovf: false,
                  data:[
                    { label: "Power", value: "Power" },
                    { label: "Water", value: "Water" },
                  ]
                }
              },{ 
                  label: 'Meter Number',
                  type: 'text',
                  order: { form: 2 }, 
                  create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 6 } }], view: ['same_as:update.0'], 
              },
            ]
          },
        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})