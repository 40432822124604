
import { atom } from "recoil";

export const notificationState = atom({
    key: 'notificationState',
    default: [
        // {
        //     title: 'Your order is placed.',
        //     description: 'If several languages coalesce the grammar',
        //     link: "/modules",
        //     created_at: '2020-01-12 11:15',
        //     read_at: null,
        //     avatar_type: 'icon',
        //     avatar_path: 'bx bx-cart',
        //     avatar_bg: 'bg-primary',
        // },
        // {
        //     title: 'James Lemire',
        //     description: 'It will seem like simplified English',
        //     link: "https://www.google.com",
        //     created_at: '2020-01-12 11:40',
        //     read_at: null,
        //     avatar_type: 'icon',
        //     avatar_path: 'bx bx-user',
        //     avatar_bg: 'bg-info',
        // },
        // {
        //     title: 'Your item is shipped',
        //     description: 'If several languages coalesce the grammar',
        //     link: null,
        //     created_at: '2020-01-12 11:50',
        //     read_at: null,
        //     avatar_type: 'icon',
        //     avatar_path: 'bx bx-badge-check',
        //     avatar_bg: 'bg-success',
        // },
        // {
        //     title: 'Salena Layfield',
        //     description: 'As a skeptical Cambridge friend of mine accidental',
        //     link: null,
        //     created_at: '2020-01-12 09:25',
        //     read_at: '2020-01-12 11:15',
        //     avatar_type: 'icon',
        //     avatar_path: 'bx bx-user',
        //     avatar_bg: 'bg-info',
        // }
    ],
})