import { atom, selector } from "recoil";
import { updateDataRequest, getBusinessTypeListRequest, getCountryListRequest, getServiceCategoryListRequest } from "../../../api/controller/ProfileController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const profileListState = atom({
    key: 'profileListState',
    default: null,
})
export const profileBusinessTypeState = atom({
	key: 'profileBusinessTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const profileRegisteredCountryState = atom({
	key: 'profileRegisteredCountryState',
	default:{
		data:[],
		ovf:false,
	}
})
export const profileServiceCategoryState = atom({
	key: 'profileServiceCategoryState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: true }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: () => false } ], 
    update: [ { name: 'Default Update', action: updateDataRequest, closeOnSubmit: true } ],
    view: [ { name: 'Default View' } ],
}

export const profileDataStructureState = selector({
    key: 'profileDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Legal Entity Name',
                field: 'legal_name',
                name: 'LegalName',
                type: 'text',
                order: { form: 1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Business Type',
                type: 'select',
                order: { form: 2 }, 
                rootValue: true,
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, creatable: true, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(profileBusinessTypeState),
				getRequest: getBusinessTypeListRequest,
            },
            { 
                label: 'Service Category',
                type: 'multiselect',
                field:'service_categories',
                order: { form: 2.1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(profileServiceCategoryState),
				getRequest: getServiceCategoryListRequest,
            },
			{ 
                label: 'Registered Country',
                type: 'select',
                order: { form: 3 }, 
                rootValue: true,
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(profileRegisteredCountryState),
				getRequest: getCountryListRequest,
            },
			{ 
                label: 'Year Of Incorporation',
                type: 'number',
                order: { form: 4 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Registration Number',
                type: 'text',
                order: { form: 5 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'No. of Years Established Locally',
                name: "YearsEstablishedLocally",
                field: "years_established_locally",
                type: 'number',
                order: { form: 6 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'No. of Years Established Internationally',
                name: "YearsEstablishedInternationally",
                field: "years_established_internationally",
                type: 'number',
                order: { form: 7 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Website',
                type: 'text',
                order: { form: 7.1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12 } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Tax Registration Numbers',
                type: 'text',
                order: { form: 8 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Representative Name',
                type: 'text',
                order: { form: 9 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Representative Designation',
                type: 'text',
                order: { form: 9.1 }, 
                section: { id: 1, label: 'General' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Registered Addresses',
                name: "Addresses",
                field: "addresses",
                type: 'repeater',
                section: { id: 2, label: 'Address' },
                order: { form: 10 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
					{ 
                		label: 'Address Line 1',
                		type: 'text',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 12 }, subText: "Building Name, Street Name", validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Address Line 2',
                		type: 'text',
                		order: { form: 3 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 12 }, subText: "Apartment/Unit/Suite Number" }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Address Line 3',
                		type: 'text',
                		order: { form: 4 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 12 }, subText: "City/Region, State/Province, Postal Code" }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'Country',
                		type: 'select',
                        rootValue: true,
                		order: { form: 5 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				        list: get(profileRegisteredCountryState),
				        getRequest: getCountryListRequest,
            		},
					
                ]
            },
			{ 
                label: 'Contact Numbers',
                type: 'repeater',
                order: { form: 11 },
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false, hideLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'Contact Number',
                		type: 'number',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'Emails',
                type: 'repeater',
                order: { form: 12 },
                section: { id: 3, label: 'Contact' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false, hideLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'Email',
                		type: 'email',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'required' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'Company Registration',
                type: 'file',
                order: { form: 13 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, subText: "PDF / JPG / PNG" }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Business Permit Authorization',
                type: 'file',
                order: { form: 14 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, subText: "PDF / JPG / PNG" }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Tax Registration Certificate',
                type: 'file',
                order: { form: 15 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, subText: "PDF / JPG / PNG" }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Profile Sheet',
                type: 'file',
                order: { form: 16 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, subText: "PDF / JPG / PNG" }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Incumbency Certificate',
                type: 'file',
                order: { form: 17 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, subText: "Foreign Companies Only" }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'I Acknowledge that I am not a defaulter of any bank or financial institution',
                name: 'NonDefaulterAcknowledgement',
                field: 'non_defaulter_acknowledgement',
                type: 'checkbox',
                order: { form: 18 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12 }, inlineLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'I Acknowledge that the company has no pending legal actions against it',
                name: 'NoPendingLegalActionsAcknowledgement',
                field: 'no_pending_legal_actions_acknowledgement',
                type: 'checkbox',
                order: { form: 19 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12 }, inlineLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'I Acknowledge that the information provided is accurate',
                name: 'ProvidedAccurateInformationAcknowledgement',
                field: 'provided_accurate_information_acknowledgement',
                type: 'checkbox',
                order: { form: 19 }, 
                section: { id: 4, label: 'Uploads' },
                create: [{ enabled: true, size: { xs: 12 }, validations: 'required', inlineLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
