import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/ModuleController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const moduleListState = atom({
    key: 'moduleListState',
    default: [],
})


export const modalConfigDefault = { size: "md", wizard: false }

export const moduleFilterState = selector({
    key: 'moduleFilterState',
    get: () => ([])
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const moduleDataStructureState = selector({
    key: 'moduleDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Name',
                type: 'text',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Description',
                type: 'text',
                order: { form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Uuid',
                type: 'text',
                order: { form: 2 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }  }], 
            },
			{ 
                label: 'Type',
                type: 'select',
                order: { table: 2, form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'Public', value: 'Public' },
						{ label: 'Private', value: 'Private' },
						{ label: 'Invite-Only', value: 'Invite-Only' },
					]
				},
            },
			{ 
                label: 'Icon',
                type: 'file',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Logo',
                type: 'file',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'App Url',
                type: 'text',
                order: { form: 5.1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Api Url',
                type: 'text',
                order: { form: 5.2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Auth Url',
                type: 'text',
                order: { form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
