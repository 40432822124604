import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
import ForgotPassword from "../app/guest/ForgotPassword";
import ChangePassword from "../app/guest/ChangePassword";
import Home from "../app/auth/Home";
import Users from "../app/auth/users";
import Roles from "../app/auth/roles";
import Permissions from "../app/auth/permissions";
import NonAuthLayout from "../components/NonAuthLayout";
import LocationTypes from "../app/auth/locationTypes"
import Transactions from "../app/auth/transactions"
import Locations from "../app/auth/locations"
import ServiceCategories from "../app/auth/serviceCategories";
import Modules from "../app/auth/modules";
import BusinessTypes from "../app/auth/businessTypes";
import Register from "../app/guest/Register";
import Profile from "../app/auth/profile";
import VerticalNoSidebarLayout from "../components/VerticalNoSidebarLayout";

// import HorizontalLayout from "../components/HorizontalLayout";
// import GuestHome from "../app/guest/Home";

const navigation = [
  {
    name: "Login",
    icon: "bx bx-lock",
    link: "/login",
    defaultRoute: true,
    component: Login,
    layout: NonAuthLayout,
    middleware: "guest",
    hidden: true,
    children: [],
  },
  {
    name: "Register",
    icon: "bx bx-lock",
    link: "/register",
    component: Register,
    layout: NonAuthLayout,
    middleware: "guest",
    hidden: true,
    children: [],
  },

  {
    name: "Forgot Password",
    icon: "bx bx-lock",
    link: "/forgot-password",
    component: ForgotPassword,
    layout: NonAuthLayout,
    middleware: "guest",
    hidden: true,
    children: [],
  },
  {
    name: "Change Password",
    icon: "bx bx-lock",
    link: "/change-password",
    component: ChangePassword,
    layout: NonAuthLayout,
    middleware: "guest",
    hidden: true,
    children: [],
  },

  {
    name: "Main",
    icon: "bx bx-group",
    children: [
        {
          name: "Home",
          icon: "mdi mdi-home",
          link: "/home",
          defaultRoute: true,
          component: Home,
          middleware: "auth",
          children: [],
          unpermission: "read-users",
          hidden: true,
          layout: VerticalNoSidebarLayout,
        },
        {
          name: "Company Profile",
          icon: "mdi mdi-account",
          link: "/profile",
          component: Profile,
          middleware: "auth",
          permission: "",
          children: [],
          unpermission: "read-users",
          hidden: true,
          layout: VerticalNoSidebarLayout,
        },
        {
          name: "Home",
          icon: "mdi mdi-home",
          link: "/home",
          defaultRoute: true,
          component: Home,
          middleware: "auth",
          children: [],
          permission: "read-users",
        },
        {
          name: "Company Profile",
          icon: "mdi mdi-account",
          link: "/profile",
          component: Profile,
          middleware: "auth",
          permission: "read-users",
          children: [],
        },
        {
          name: "Transactions",
          icon: "mdi mdi-receipt",
          link: "/transactions",
          component: Transactions,
          middleware: "auth",
          permission: "read-users",
          children: [],
        },
    ],
  },	
  {
    name: 'Master',
    icon: "bx bx-group",
    children: [
        { // Location Route Begin
            name: 'Location',
            icon: 'mdi mdi-map-marker',
            link: '/locations',
            component: Locations,
            middleware: 'auth',
            permission: 'read-locations',
            children: [],
        }, // Location Route End
        { // BusinessType Route Begin
            name: 'Business Type',
            icon: 'mdi mdi-domain',
            link: '/businessTypes',
            component: BusinessTypes,
            middleware: 'auth',
            permission: 'read-businessTypes',
            children: [],
        }, // BusinessType Route End
        { // ServiceCategory Route Begin
            name: 'Service Category',
            icon: 'mdi mdi-shape',
            link: '/serviceCategories',
            component: ServiceCategories,
            middleware: 'auth',
            permission: 'read-serviceCategories',
            children: [],
        }, // ServiceCategory Route End
        { // LocationType Route Begin
            name: 'Location Type',
            icon: 'mdi mdi-sitemap',
            link: '/locationTypes',
            component: LocationTypes,
            middleware: 'auth',
            permission: 'read-locationTypes',
            children: [],
        }, // LocationType Route End
        { // Module Route Begin
            name: 'Modules',
            icon: 'mdi mdi-view-module',
            link: '/modules',
            component: Modules,
            middleware: 'auth',
            permission: 'read-modules',
            children: [],
        }, // Module Route End
      ]
    },
    {
      name: 'ACL',
      icon: "bx bx-group",
      children: [
        {
            name: 'Users',
            icon: 'bx bx-group',
            link: '/acl/users',
            component: Users,
            middleware: 'auth',
            permission: 'read-users',
            children: []
        },
        {
            name: 'Roles',
            icon: 'mdi mdi-account-key-outline',
            link: '/acl/roles',
            component: Roles,
            middleware: 'auth',
            permission: 'read-roles',
            children: []
        },
        {
            name: 'Permissions',
            icon: 'bx bx-lock-open-alt',
            link: '/acl/permissions',
            component: Permissions,
            middleware: 'auth',
            permission: 'read-permissions',
            children: []
        },
      ]
    },
];

const megaMenu = [
  // {
  //     name: 'UI Components',
  //     size: 4,
  //     children: [
  //         {
  //             name: 'Lightbox',
  //             link: '/lightbox',
  //             component: GuestHome,
  //             middleware: 'guest',
  //         },
  //         {
  //             name: 'Range Slider',
  //             link: '/rangeslider',
  //             component: GuestHome,
  //             middleware: 'guest',
  //         },
  //     ]
  // },
  // {
  //     name: 'Applications',
  //     size: 4,
  //     children: [
  //         {
  //             name: 'Ecommerce',
  //             link: '/ecommerce',
  //             component: GuestHome,
  //             middleware: 'guest',
  //         },
  //         {
  //             name: 'Calendar',
  //             link: '/calendar',
  //             component: GuestHome,
  //             middleware: 'guest',
  //         },
  //     ]
  // },
  // {
  //     name: 'Extra Pages',
  //     size: 4,
  //     children: [
  //         {
  //             name: 'Maintenance',
  //             link: '/maintenance',
  //             component: GuestHome,
  //             middleware: 'guest',
  //         },
  //         {
  //             name: 'Coming Soon',
  //             link: '/comingsoon',
  //             component: GuestHome,
  //             middleware: 'guest',
  //         },
  //     ]
  // }
];

const rawNavigationState = atom({
  key: "rawNavigationState",
  default: navigation,
});

const rawMegaMenuState = atom({
  key: "rawMegaMenuState",
  default: megaMenu,
});

export const navigationState = selector({
  key: "navigationState",
  get: ({ get }) => {
    let tempNavigation = cloneDeep(get(rawNavigationState));
    const isAuth = get(authState);
    const permissions = get(permissionState);
    const parseModule = (nav) => {
      let returnVal = null;
      if (nav.children.length > 0) {
        if (
          (isAuth &&
            nav.middleware === "auth" &&
            (!nav.permission || permissions.includes(nav.permission)) &&
            (!nav.unpermission || !permissions.includes(nav.unpermission))) ||
          (!isAuth && nav.middleware === "guest") ||
          !nav.middleware
        ) {
          let tempChildren = [];
          nav.children.forEach((child) => {
            let temp = parseModule(child);
            if (temp) {
              tempChildren = [...tempChildren, temp];
            }
          });
          returnVal = { ...nav, children: tempChildren };
        }
      } else {
        if (
          (isAuth &&
            nav.middleware === "auth" &&
            (!nav.permission || permissions.includes(nav.permission)) &&
            (!nav.unpermission || !permissions.includes(nav.unpermission))) ||
          (!isAuth && nav.middleware === "guest") ||
          !nav.middleware
        ) {
          returnVal = nav;
        }
      }
      return returnVal;
    };
    // Remove Nav Items based on auth and permissions
    tempNavigation = tempNavigation
      .map((header) => parseModule(header))
      .filter((n) => n !== null);
    // Remove Empty Parents
    const filterChildren = (nav) => {
      if (nav.children.filter((c) => c.children.length > 0).length > 0) {
        nav.children = nav.children.map((child) => {
          child = filterChildren(child);
          return child;
        });
      } else {
        nav.children = nav.children.filter(
          (c) => c.children.length > 0 || c.component
        );
      }
      return nav;
    };
    tempNavigation = tempNavigation.map((header) => filterChildren(header));
    // Remove Empty Modules
    tempNavigation = tempNavigation.filter(
      (header) => header.children.length > 0 || header.component
    );
    return tempNavigation;
  },
});

export const megaMenuState = selector({
  key: "megaMenuState",
  get: ({ get }) => {
    let tempMegaMenu = cloneDeep(get(rawMegaMenuState));
    const isAuth = get(authState);
    const permissions = get(permissionState);
    tempMegaMenu = tempMegaMenu
      .map((header) => {
        header.children = header.children.filter(
          (c) =>
            (isAuth &&
              c.middleware === "auth" &&
              (!c.permission || permissions.includes(c.permission))) ||
            (!isAuth && c.middleware === "guest") ||
            !c.middleware
        );
        return header;
      })
      .filter((header) => header.children.length > 0);
    return tempMegaMenu;
  },
});
