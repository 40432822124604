import React, {useEffect, useState} from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { activeNavState, leftSidebarTypeState, } from "../../state/GlobalState";
import { changeLeftSidebarType } from "../../custom/helpers/Layout";
//i18n
// import {withNamespaces} from 'react-i18next/withNamespace';

const SidebarContent = props => {

    const setActiveNav = useSetRecoilState(activeNavState)
    const [leftSidebarType, setLeftSidebarType] = useRecoilState(leftSidebarTypeState)
    const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

    const toggleMenu = (menu) => {
        if(menu.children.filter(c=>!c.hidden).length === 0 && isMobile){
            if (leftSidebarType === "default") {
                setLeftSidebarType("condensed");
                changeLeftSidebarType("condensed", isMobile)
            } else if (leftSidebarType === "condensed") {
                setLeftSidebarType("default");
                changeLeftSidebarType("default", isMobile)
            }
        }
    };

    useEffect(() => {
        initMenu()
        // eslint-disable-next-line
    }, [props.type])


    useEffect(() => {
        setActive()
        // eslint-disable-next-line
    }, [props])

    const initMenu = () => {
        new MetisMenu("#side-menu");
        setActive()
    }
    

    const getActiveNav = (nav) => {
        if(nav.children.length > 0){
            return nav.children.map((c)=>(getActiveNav(c))).join('') 
        } else if(props.location.pathname.replace(/^\/|\/$/g, '') === nav.link?.replace(/^\/|\/$/g, '')){
            setActiveNav(nav)
            return nav.highlight?.replace(/^\/|\/$/g, '')
        } else {
            return ''
        }
    }

    const setActive = () => {
        var matchingMenuItem = null;
        const highlight = props.navItems.map((m)=>(getActiveNav(m))).join('')
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        const currentPath = props.location.pathname.replace(/^\/|\/$/g, '');
        for (var i = 0; i < items.length; ++i) {
            if (currentPath === items[i].pathname.replace(/^\/|\/$/g, '') || (highlight && items[i].pathname.replace(/^\/|\/$/g, '') === highlight)) {
                matchingMenuItem = items[i];
            } else {
                items[i].classList.remove("active")
                let parent = items[i].parentElement, count = 0;
                while(parent.id !== "side-menu"){
                    parent.classList.remove(count%2 === 0 ? "mm-active" : "mm-show")
                    parent = parent.parentElement
                    count++
                }
            }
        }
        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active"); // a
            let parent = matchingMenuItem.parentElement, count = 0;
            while(parent.id !== "side-menu"){
                parent.classList.add(count%2 === 0 ? "mm-active" : "mm-show")
                parent = parent.parentElement
                count++
            }
        }
    }

    const parseSubMenu = (menu) => (
        <ul className="sub-menu">
            {menu.children.filter(c=>!c.hidden).map((child, index) => (
                child.children.filter(c=>!c.hidden).length > 0 ? (
                    <li key={child?.name + index.toString()}>
                        <Link to={child.link || "/#"} className={`waves-effect has-arrow`}>
                            <span>{child.name}</span>
                        </Link>
                        {parseSubMenu(child)}
                    </li>
                ) : <li key={child?.name + index.toString()}><Link to={child.link} onClick={()=>toggleMenu(child)}>{child.name}</Link></li>
            ))}
        </ul>
    )


    return (
        <React.Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    {props.navItems.filter(c=>!c.hidden).map((module,key)=>(
                        module?.children?.length > 0 ?
                        (
                            <React.Fragment key={module.name + key.toString()}>
                                <li className="menu-title">{module.name}</li>
                                {module?.children?.filter(c=>!c.hidden).map((menu, index) => {
                                    return (
                                        <li key={menu?.name + index.toString()}>
                                            <Link to={menu.link || "/#"} onClick={()=>toggleMenu(menu)} className={`waves-effect ${menu.children.filter(c=>!c.hidden).length ? 'has-arrow' : ''}`}>
                                                <i className={menu.icon}/>
                                                <span>{menu.name}</span>
                                            </Link>
                                            {menu?.children?.filter(c=>!c.hidden).length > 0 ? (
                                                parseSubMenu(menu)
                                            ) : null}
                                        </li>
                                    )
                                })}
                            </React.Fragment>
                        ) : (
                            <li key={module?.name + key.toString()}>
                                <Link to={module.link || "/#"} onClick={()=>toggleMenu(module)} className={`waves-effect ${module.children.filter(c=>!c.hidden).length ? 'has-arrow' : ''}`}>
                                    <i className={module.icon}/>
                                    <span>{module.name}</span>
                                </Link>
                            </li>
                        )
                    ))}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default withRouter((SidebarContent));
