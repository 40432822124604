import React, { useEffect, useState } from "react";

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
// import { AvField, AvForm } from 'availity-reactstrap-validation';

// import images
// import profile from "../../assets/images/profile-img.png";
import bgImage from "../../assets/images/Picture2.jpg";
// import logoFull from "../../assets/images/logo_im2.png";
import logoOriginal from "../../assets/images/logoOriginal.png";
// import logo from "../../assets/images/logo.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loginErrorState } from "../../state/ErrorState";
import { passwordResetHashState } from "../../api/state/AuthState";
import { themeTypeState } from "../../state/GlobalState";
import moment from "moment";
import {
  completeRegistration,
  completeEmail,
  initEmail,
  // getCountryListRequest,
} from "../../api/controller/RegisterController";
import Select from "react-select";

const Register = ({ history, handleAuthResponse }) => {
  const [error, setError] = useRecoilState(loginErrorState);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem("authUser"))
  // );
  const setPasswordResetHash = useSetRecoilState(passwordResetHashState);
  const [emailReference, setEmailReference] = useState(null);
  // const [mobileReference, setMobileReference] = useState(null);
  const [themeType] = useRecoilState(themeTypeState);
  const [form, setForm] = useState({
    Email: "",
    EmailReferenceNumber: "",
    MobileNumber: "",
    MobileReferenceNumber: "",
    MobileOtp: "",
    Fullname: "",
    EmailOtp: "",
    Password: "",
    Password_confirmation: "",
    RegisteredCountry: "",
  });
  const [currentStep, setCurrentStep] = useState("email"); // steps are mobile, otp, register

  // handleValidSubmit
  const handleValidSubmit = async (event) => {
    event.preventDefault();
    setSubmitDisabled(true);
    let response = null;
    if (currentStep === "email") {
      // setCurrentStep("email-otp"); // TODO: Temp. remove
      response = await initEmail({ Email: form.Email }); // TODO
      console.log(response);
      if (response?.Reference) {
        setEmailReference(response.Reference);
        setCurrentStep("email-otp");
      }
    } else if (currentStep === "email-otp") {
      // setCurrentStep("register"); // TODO: temp. remove
      response = await completeEmail({
        EmailReference: emailReference,
        EmailOtp: form.EmailOtp,
      });
      console.log(response);
      if (response?.Status === "success") {
        // if (form.RegisteredCountry?.value !== "Maldives") {
        //   setCurrentStep("register");
        // } else {
        //   setCurrentStep("mobile");
        // }
        setCurrentStep("register");
      }
    } else if (currentStep === "mobile") {
      // response = await initMobile({ MobileNumber: form.MobileNumber });
      // if (response?.Reference) {
      //   setMobileReference(response.Reference);
      //   setCurrentStep("mobile-otp");
      // }
    } else if (currentStep === "mobile-otp") {
      // response = await completeMobile({
      //   MobileReference: mobileReference,
      //   MobileOtp: form.MobileOtp,
      // });
      // if (response?.Status === "success") {
      //   setCurrentStep("register");
      // }
    } else if (currentStep === "register") {
      response = await completeRegistration({
        EmailReference: emailReference,
        // MobileReference: mobileReference,
        MobileNumber: form?.MobileNumber,
        RegisteredCountry: form.RegisteredCountry?.value,
        Fullname: form.Fullname,
        Password: form.Password,
        Password_confirmation: form.Password_confirmation,
      });
    }
    if (response?.decodedToken) {
      handleAuthResponse(response, response.encodedToken, false, true);
    } else if (response?.scp) {
      localStorage.removeItem("authToken");
      setPasswordResetHash(response.hash);
      history.push("/change-password");
    } else if (response?.Status === "error") {
      if (response.payload?.errors) {
        Object.keys(response.payload.errors).forEach(function (key) {
          response.payload.errors[key].forEach((val) => {
            setError(val);
          });
        });
      } else {
        setError(response.Message);
      }
      setSubmitDisabled(false);
    } else {
      setError("");
      setSubmitDisabled(false);
    }
  };

  var countryList = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, The Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote D'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic Of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic Peoples Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People'S Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "RWANDA",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia and Montenegro",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  useEffect(() => {
    setError("");
  }, [setError]);

  // useEffect(() => {
    // setThemeType("light");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
            </div> */}
      <div>
        <img
          src={bgImage}
          alt=""
          style={{
            zIndex: "-10",
            position: "fixed",
            opacity: 0.8,
            objectFit: "cover",
          }}
        />
      </div>
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <Card className="overflow-hidden mt-5">
                <div className="bg-soft border-bottom">
                  <Col className="col-12 text-center pt-3 mt-2">
                    <img src={logoOriginal} height="60px" alt="" />
                  </Col>
                  <Row>
                    <Col className="col-12 text-center mb-2">
                      <div
                        className={
                          "px-3 mt-4 " +
                          (themeType === "dark" ? "text-primary" : "text-white")
                        }
                      ></div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="p-0">
                  <div className="p-2">
                    <div className="col-12 pt-3 p-2">
                      <h5
                        className={
                          themeType === "dark" ? "text-white" : "text-primary"
                        }
                      >
                        Registration
                      </h5>
                      {currentStep === "email-otp" ? (
                        <p>
                          Please enter the OTP code sent to <b>{form?.Email}</b>
                        </p>
                      ) : null}
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={handleValidSubmit}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        {currentStep === "email" ? (
                          <>
                            <FormGroup>
                              <Label>Country of Registration</Label>
                              {/* select from react select */}
                              <Select
                                options={countryList.map((i) => ({
                                  label: i,
                                  value: i,
                                }))}
                                value={form.Country}
                                onChange={(e) =>
                                  setForm({ ...form, RegisteredCountry: e })
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Email</Label>
                              <Input
                                value={form.Email}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    Email: e.target.value,
                                  })
                                }
                                name="email"
                                label="Email"
                                className="form-control"
                                placeholder="Enter Email"
                                type="email"
                                required
                              />
                            </FormGroup>
                          </>
                        ) : null}
                        {currentStep === "email-otp" ? (
                          <FormGroup>
                            <Label>Email OTP</Label>
                            <Input
                              value={form.EmailOtp}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  EmailOtp: e.target.value,
                                })
                              }
                              name="Email OTP"
                              label="We sent an OTP via email"
                              className="form-control"
                              placeholder="Enter OTP Sent to your Email"
                              type="number"
                              required
                            />
                          </FormGroup>
                        ) : null}
                        {currentStep === "register" ? (
                          <FormGroup>
                            <Label>Mobile Number</Label>
                            <Input
                              value={form.MobileNumber}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  MobileNumber: e.target.value,
                                })
                              }
                              name="mobile"
                              label="Mobile Number"
                              className="form-control"
                              placeholder="Enter Mobile Number"
                              type="number"
                              required
                            />
                          </FormGroup>
                        ) : null}
                        {currentStep === "mobile-otp" ? (
                          <FormGroup>
                            <Label>Mobile OTP</Label>
                            <Input
                              value={form.MobileOtp}
                              onChange={(e) =>
                                setForm({ ...form, MobileOtp: e.target.value })
                              }
                              name="Mobile OTP"
                              label="We sent an OTP via text"
                              className="form-control"
                              placeholder="Enter OTP sent to your Mobile"
                              type="number"
                              required
                            />
                          </FormGroup>
                        ) : null}
                        {currentStep === "register" ? (
                          <React.Fragment>
                            <FormGroup>
                              <Label>Legal Entity Name</Label>
                              <Input
                                value={form.Fullname}
                                onChange={(e) =>
                                  setForm({ ...form, Fullname: e.target.value })
                                }
                                name="Fullname"
                                label="Fullname"
                                className="form-control"
                                placeholder="Enter your name"
                                type="text"
                                required
                              />
                            </FormGroup>
                            {/* <FormGroup>
                              <Label>Email</Label>
                              <Input
                                value={form.Email}
                                onChange={(e) =>
                                  setForm({ ...form, Email: e.target.value })
                                }
                                name="Email"
                                label="Email"
                                className="form-control"
                                placeholder="Enter Email"
                                type="email"
                                required
                              />
                            </FormGroup> */}
                            <FormGroup>
                              <Label>Password</Label>
                              <Input
                                value={form.Password}
                                onChange={(e) =>
                                  setForm({ ...form, Password: e.target.value })
                                }
                                name="Password"
                                label="Password"
                                type="password"
                                required
                                placeholder="Enter Password"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Confirm Password</Label>
                              <Input
                                value={form.Password_confirmation}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    Password_confirmation: e.target.value,
                                  })
                                }
                                name="Password_confirmation"
                                label="Password"
                                type="password"
                                required
                                placeholder="Confirm Password"
                              />
                            </FormGroup>
                          </React.Fragment>
                        ) : null}

                        <div className="mt-4">
                          <button
                            disabled={submitDisabled}
                            className="btn btn-primary btn-block waves-effect waves-light w-100"
                            type="submit"
                          >
                            {currentStep === "register" ? "Register" : "Next"}
                          </button>
                        </div>
                        <p className="mt-3 mb-3" align="center">
                          - OR -
                        </p>
                        <div className="mt-2">
                          <Link to="/login">
                            <button
                              className="btn btn-light btn-block waves-effect waves-light w-100"
                              type="button"
                            >
                              Have an account? Back to Login
                            </button>
                          </Link>
                        </div>
                      </Form>
                    </div>

                    <div className="mt-5 text-center">
                      <div
                        style={{
                          fontSize: "10px",
                          color: themeType === "dark" ? "white" : "",
                        }}
                      >
                        © {moment().year()} Maldives Aiports Company Limited
                        (MACL)
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);
