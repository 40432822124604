
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getModulesRequest, requestModuleRequest } from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { useRecoilValue } from "recoil";
import { userState } from "../../api/state/AuthState";
import { createTokenRequest } from "../../api/controller/ExtController";
import { useLocation, useHistory } from "react-router";
import notify from "../../custom/helpers/Notify";
import { Loader } from "../../custom/components/Loader";
import Placeholder from "../../assets/images/module-ph.png"

const Home = () => {
    
    const location = useLocation()
    const history = useHistory()

    const [modules, setModules] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const user = useRecoilValue(userState)

    useEffect(() => {
        async function getData(){
            const response = await getModulesRequest()
            if(response && response.data){
                setModules(response.data)
            } else {
                handleResponse(response)
            }
        }
        getData()
    }, [])

    useEffect(() => {
        const error = new URLSearchParams(location.search).get("error")
        if(error){
            notify({status:"error", title:"", message: error})
            history.push("/home")
        }
    }, [location, history])

    const handleRequestClick = async (id) => {
        setButtonLoading(true)
        const response = await requestModuleRequest({id: id})
        handleResponse(response)
        if(response && response.Status === "success"){
            setModules(modules.map((m) => id === m.id ? {...m, requested: true, requestable: false} : m))
        }
        setButtonLoading(false)
    }

    const handleGotoModule = async (id) => {
        setButtonLoading(true)
        const response = await createTokenRequest({id: id})
        if(response && response.Status === "success"){
            window.location.replace(response.auth_url+"?token="+response.token+"&endpoint="+response.endpoint)

        } else {
            handleResponse(response)
            setButtonLoading(false)
        }
    }

    return (
        <div className="modules-full-bg">
            <div className="page-content bg-overlay" style={{overflowY:'scroll'}}>
                <Container>
                    <Row>
                        <Loader loading={buttonLoading} transparent />
                        <div className="module-bg">
                            <Row>
                                {modules.map((m,k) => (
                                    <Col xs={12} md={6} lg={4} key={k}>
                                        <Card align="center">
                                            <CardBody>
                                                <Row>
                                                    <Col xs={4} style={{borderRight:'1px solid lightgray'}}>
                                                        <img src={m.logo ? ((process.env.REACT_APP_STORAGE_TYPE === "azure" ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL) + m.logo) : Placeholder} alt={m.name} className="img-fluid pt-4" />
                                                    </Col>
                                                    <Col xs={8} align="left">
                                                        <h4 className="card-title mb-2">{m.name}</h4>
                                                        <p style={{height:'55px'}}>{m.description}</p>
                                                        {m.requested && <p align="right" className="text-info">Requested</p>}
                                                        {m.granted && user.approved ? <button style={{float:'right'}} className="btn btn-dark" disabled={buttonLoading} onClick={() => handleGotoModule(m.id)}>Manage <i className="mdi mdi-location-enter"></i></button> : null}
                                                        {m.requestable && <button style={{float:'right'}} className="btn btn-light" disabled={buttonLoading} onClick={() => handleRequestClick(m.id)}>Request Access</button>}
                                                        {((!m.requestable && !m.granted && m.type === "Public") || (m.granted && !user.approved)) && <p align="right" className="text-info">Awaiting User Approval</p>}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Row>
                        
                </Container>
            </div>
        </div>
        
    )
    
}

export default Home;

