import React from "react";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { forwardRef } from "react";

const RadioEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        };
    });

    return (
        <div style={{maxWidth:"300px", padding: "10px"}}>
            {props.values.list?.map((radio,key)=>(
                <div key={key} className="form-check mb-1" onClick={() => {setValue(radio.value)}}>
                    <input className="form-check-input" type="radio" /*name={getPN(val)}*/ onChange={() => false} value={radio.value} checked={Boolean(value === radio.value)} />
                    <label className="form-check-label">{radio.label}</label>
                </div>
            ))}
        </div>
    );
});
RadioEditor.displayName = "RadioEditor";

export default RadioEditor;