import http from "../core/http";
const group = 'company';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}


export const getBusinessTypeListRequest =  async (data) => {
	return await http.post(`${group}/getBusinessTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getCountryListRequest =  async (data) => {
	return await http.post(`${group}/getCountryList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getServiceCategoryListRequest =  async (data) => {
	return await http.post(`${group}/getServiceCategoryList`, data).then(res => res.data).catch(http.defaultCatch);
}



export const initMobile =  async (data) => {
    return await http.post(`${group}/initMobile`, data).then(res => res.data).catch(http.defaultCatch);
}
export const completeMobile =  async (data) => {
    return await http.post(`${group}/completeMobile`, data).then(res => res.data).catch(http.defaultCatch);
}