import { useRecoilValue } from "recoil"
import { permissionState } from "../../api/state/AuthState"
import { pageState } from "../../state/GlobalState"


export const HasPermission = (permission) => {
    const page = useRecoilValue(pageState)
    const permissions = useRecoilValue(permissionState)
    if(! permission){
        return true
    }
    let absolute = false;
    if(permission.substr(0,1) === ":"){
        absolute = true;
        permission = permission.substr(1)
    }
    permission = absolute ? permission : permission + "-" + page
    return permissions?.includes(permission)
}