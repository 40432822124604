import React from 'react';


export const Loader = ({loading, size="", transparent=false, message=""}) => {

    return (
        <div id={"preloader" + (transparent ? "-tp" : "")} style={{position: 'absolute', zIndex:"9", display: loading ? "block" : "none"}}>
            <div id={"status" + (size === "xs" ? "-xs" : "")} style={{display: loading ? "block" : "none"}}>
                <div className={"spinner-chase" + (size === "xs" ? "-xs" : "")}>
                    <div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div>
                </div>
            </div>
            <div className='m-1'>{message?.length > 0 && <h4 align="center" class="mt-3">{message}</h4>}</div>
        </div>
    )

}